<template>
  <div class="g col-12 c">
    <div class="card">
        <div class="card-body">
            <button class="btn btn-primary btn-sm" @click="send()">
                <i class="fa fa-paper-plane"></i>
                ارسال الانذارات لاولياء الامور
            </button>
            &nbsp;
            <select v-model="length">
                <option :value="0">عرض كل الايام</option>
                <option :value="2">من بلغ 3 ايام غياب</option>
                <option :value="4">من بلغ 5 ايام غياب</option>
                <option :value="9">من بلغ 10 ايام غياب</option>
            </select>
            &nbsp;
            <select v-model="classroom">
                <option value="all">عرض كل الفصول</option>
                <option v-for="o in classrooms" :key="o" :value="o">{{ o }}</option>
            </select>
            <br><br>
            <div class="alert alert-warning text-center text-dark g">
                <span class="text-dark">سيتم عرض من تجاوز غيابه 3 ايام بدون عذر فاكثر.</span>
            </div>
            <table id="headerTable" class="table table-hover table-bordered table-sm">
                <h4 id="datex" class="dnone"></h4>
              <thead>
                <th>
                  م
                </th>
                <th>
                  اسم الطالب
                </th>
                <th>
                  رقم الهوية
                </th>
                <th>
                  الفصل
                </th>
                <th>
                  ايام الغياب
                </th>
                <th>
                  طباعة الانذار
                </th>
              </thead>
              <tbody>
                <template v-for="(student, c) in students">
                  <tr :key="c" v-if="(classroom == 'all' || classroom == student.classroom) && student.days.length > 2 && student.days.length > length">
                      <td>{{ c + 1 }}</td>
                      <td>{{ student.name }}</td>
                      <td>{{ student.number }}</td>
                      <td>{{ student.classroom }}</td>
                      <td>
                        {{ student.days.length }}
                      </td>
                      <td>
                        <button class="btn btn-sm btn-info" style="border-radius: 0px" v-if="student.days.length > 2" @click="printA(student, 1)">
                            <i class="fa fa-print"></i>
                            الانذار الاول
                        </button>
                        <button class="btn btn-sm btn-warning" style="border-radius: 0px" v-if="student.days.length > 4" @click="printA(student, 2)">
                            <i class="fa fa-print"></i>
                            الانذار الثاني
                        </button>
                        <button class="btn btn-sm btn-danger" style="border-radius: 0px" v-if="student.days.length > 9" @click="printA(student, 3)">
                            <i class="fa fa-print"></i>
                            الانذار الثالث
                        </button>
                      </td>
                  </tr>
                </template>
              </tbody>
            </table>
        </div>
    </div><button
      class="dnone"
      id="notjoinstudentsalert" @click="messageChanged()"
      v-b-modal.modal-teachers-lessons-send2ss
    ></button>
    <b-modal id="modal-teachers-lessons-send2ss" size="lg" title="ارسال تنبيه" hide-footer>
      <div class="col-12 text-center g">
            <div class="alert alert-success text-center g" v-if="view_archive">
                <img src="/files/done.gif" style="border-radius: 50%">
                <br>
                <br>
                تم استلام الرسائل بنجاح وجاري ارسالها الآن٫<br>
                يمكنك متابعة الارسال <a :href="`/archive?school_id=${school_id}`" target="_blank"><strong><u>من هنا</u></strong></a>
            </div>
            <template v-if="!loading && !view_archive">
                <button class="btn btn-lg btn-primary" v-if="selected == 'sms' || selected == 'whatsapp-api'" @click="sendNow(selected)">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
                <div class="col-12 text-center alert alert-success" v-if="selected == 'whatsapp'" id='remove-me-wa'>
                    <h4>
                        لتتمكن من ارسائل رسائل الواتسآب، يجب عليك تحميل أداة الخدمات الاضافية من سوق كروم.
                        <br><br>
                        <a href="https://bit.ly/3yAC50a" target="_blank" class="btn btn-success">
                            <i class="fa fa-download"></i>
                            إضغط هنا لتثبيت الاداة
                        </a>
                        <br>
                        وبعد تثبيتها قم بإعادة تحديث هذه الصفحة.
                    </h4>
                </div>
                <button class="btn btn-lg btn-primary" v-if="selected == 'whatsapp'" @click="sendNow(selected)" id="brmja-send">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
            </template>
            <template v-if="loading && !view_archive">
                <button class="btn btn-lg btn-primary disabled">
                    جاري الإرسال...
                </button>
            </template>
        </div>
        <div class="form-group">
          <label for="">محتوى الرسالة</label>
          <textarea class="form-control" v-model="message" @keyup="messageChanged()" rows="5"></textarea>
        </div>
        <div class="col-12 table-responsive">
            <table class="table table-sm table-hover" id="brmja-messages-table">
                <thead>
                    <th>
                        الجوال
                    </th>
                    <th>
                        الطالب
                    </th>
                    <th>
                        الرسالة
                    </th>
                </thead>
                <tbody>
                    <tr v-for="(teacher, c) in teachers" :key="c">
                        <td>{{ teacher.phone }}</td>
                        <td>{{ teacher.name }}</td>
                        <td><textarea class='form-control msg' readonly v-model="teacher.message"></textarea></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            school_name: decodeURI(window.location.href.split("school_name=")[1].split("&")[0]),
            school_id: window.location.href.split("school_id=")[1].split("&")[0],
            message: `ولي امر الطالب {name}
برجاء العلم ان بنكم تجاوز عدد ايام غيابه بدون عذر {count} يوم
ايام الغياب:
{days}
برجاء التواصل مع ادارة المدرسة لمراجعة الانذارات`,
            students: [],
            loading: false,
            view_archive: false,
            classrooms: [],
            selected_classroom: "all",
            selected: "",
            alerts: [],
            show: 'all',
            length: 0,
            classroom: 'all',
            teachers: [],
            days: {
                0: "الاحد",
                1: "الاثنين",
                2: "الثلاثاء",
                3: "الاربعاء",
                4: "الخميس",
                5: "الجمعة",
                6: "السبت"
            },
        }
    },
    created(){
      var g = this;
      var school_id = g.school_id
      if(school_id == "undefined" || !school_id){
        alert("برجاء الدخول لحسابك في منصة مدرستي واختيار المدرسة اولاً للتعرف على الحساب.");
        window.location = "https://schools.madrasati.sa/"
      }
      $.post(api + '/api/subscribe/alerts', {
        school_id: school_id
      }).then(function(){})
      setTimeout(() => {
        $("#datex").html(new Date().toISOString().split('T')[0])
        $("body").append(`
          <textarea class='get-local-storage' name='n_students_${school_id}'></textarea>
        `)
        var i1 = setInterval(() => {
            if($(`.get-local-storage-value[name='n_students_${school_id}']`).length){
              clearInterval(i1)
              if($(`.get-local-storage-value[name='n_students_${school_id}']`).val() == "undefined" || $(`.get-local-storage-value[name='n_students_${school_id}']`).val() == "null" || !$(`.get-local-storage-value[name='n_students_${school_id}']`).val()){
                alert("برجاء استيراد الطلاب للاداة من خلال نظام نور في منصة مدرستي اولاً.\n\nقم بالذهاب لمنصة مدرستي -> اضغط على اداة المساعد الذكي -> خدمات الطلاب -> تحديث بيانات الطلاب من نور.")
              }
              try{
                g.students = JSON.parse($(`.get-local-storage-value[name='n_students_${school_id}']`).val()).map(function(f){
                    f.days = []
                    return f;
                })
              }catch{
                alert("برجاء استيراد الطلاب للاداة من خلال نظام نور في منصة مدرستي اولاً.\n\nقم بالذهاب لمنصة مدرستي -> اضغط على اداة المساعد الذكي -> خدمات الطلاب -> تحديث بيانات الطلاب من نور.")
              }
              g.students.forEach(element => {
                if(!g.classrooms.includes(element.classroom)){
                  g.classrooms.push(element.classroom)
                }
              })
              $("body").append(`
                <textarea class='get-local-storage' name='alerts'></textarea>
                `)
                var ix = setInterval(() => {
                    if($(`.get-local-storage-value[name='alerts']`).length){
                    clearInterval(ix)
                        g.alerts = JSON.parse($(`.get-local-storage-value[name='alerts']`).val())
                        var cc = [];
                        g.students.forEach(function(a){
                            g.alerts.forEach(function(b){
                                if(a.name == b.name){
                                    a.days = b.days;
                                }
                            })
                            cc.push(a)
                        })
                        g.students = cc
                    }
                }, 10);
            }
        }, 10);
      }, 500);
    },
    methods: {
        print(){
            var divToPrint=document.getElementById("headerTable");
            var newWin= window.open("");
            newWin.document.write(divToPrint.outerHTML + `<style>
                body{
                    direction: rtl;
                    text-align: right
                }
                thead{
                    background: #eee;
                }
                table, td, th {
                border: 1px solid;
                }
                tr:nth-child(even) {
                    background-color: #f2f2f2;
                }
                table {
                width: 100%;
                border-collapse: collapse;
                }
                button{
                    display:none
                }
                </style>`);
            newWin.document.close();
            newWin.print();
            newWin.close();
        },
        send(){
          $("#c-subscribe-ended").click()
          $("#after-send-settings-click").val("#notjoinstudentsalert")
        },
        messageChanged(){
            var g = this, c = [];
            var d = new Date();
            g.students.forEach(function(a){
                if((g.classroom == 'all' || g.classroom == a.classroom) && a.days.length > 2 && a.days.length > g.length){
                    var days = ""
                    a.days.forEach(function(vv){
                        days = days + `${vv.day} الموافق ${vv.date}\n`
                    })
                    a.message = g.message?.replace("{name}", a.name)?.replace("{count}", a.days.length)?.replace("{days}", days)
                    c.push(a)
                }
            })
            g.teachers = c
            if(localStorage.getItem('send')){
                g.selected = JSON.parse(localStorage.getItem('send')).selected
            }
        },
        sendNow(method){
            var g = this;
            g.loading = true
            $.post(api + '/api/messages/send', {
                school_id: localStorage.getItem('school_id'),
                arr: JSON.stringify(g.teachers),
                method: method,
                settings: localStorage.getItem('send'),
                type: 'teachers'
            })
            .then(function(response){
                g.loading = false
                if(response.status == 100){
                    g.view_archive = true
                }else{
                    alert(response.response)
                }
            })
            .fail(function(){
                g.loading = false
                alert('حدث خطأ')
            })
        },
        printA(student, count){
            var g = this;
            var myWindow=window.open('','','');
            var days = "";
            student.days.forEach(function(a, i){
                days = days + `
                <tr>
                    <td>${i+1}</td>
                    <td>${a.date}</td>
                    <td>${a.day}</td>
                </tr>
                `;
            })
            if(count == 1){
                var text = `
                <h5>
                    عزيزي الطالب/ ${student.name}
                </h5>
                <p>
                    نود تنبيهك بان مجموع ايام غياب قد بلغ ${student.days.length} ايام
                    بدون عذر تقبله المدرسة, لذا نرجو منك الاهتمام بالانتظام في الحضور الى المدرسة حيث
                    ان غيابك يؤثر على تحصيلك الدراسي وتدني مستواك بين اقرانك
                    لذا نرجو منك الحرص على عدم الغياب وانضباطك
                    في الحضور للمدرسة.
                    <br><br>
                    اسم الطالب: .............................................................
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    التاريخ: &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; / &nbsp; &nbsp;&nbsp;&nbsp;/ 1446هـ
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    التوقيع: .........................................
                </p>
                `
            }
            if(count == 2){
                var text = `<h5>
                    ولى امر الطالب/ ${student.name} &nbsp;&nbsp;&nbsp; حفظه الله
                </h5>
                <p>
                    نود احاطتكم بأن مجموع غياب ابنكم قد بلغ ${student.days.length} ايام
                    بدون عذر تقبله المدرسة٫ لذا نرجو منك الاهتمام بمتابعة ابنكم وحثه على الانتظام في حضور
                    المدرسة وسيتم الحسم من درجات المواظبة عن كل يوم دراسي بدون عذر٫ حيث ان غيابه يؤثر على تحصيله
                    الدراسي وتدني مستواه بين اقرانه لذا نرجو منكم الحرص
                    على توجيه الطالب وانضباطه في الحضور للمدرسة.
                    <br><br>
                    تم اشعار ولي الامر: &nbsp;&nbsp;
                    [&nbsp;] برسالة نصية &nbsp;&nbsp;
                    [&nbsp;] خطاب &nbsp;&nbsp;
                    [&nbsp;] اخرى: .................... &nbsp;&nbsp;
                    التاريخ: &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;/1446
                </p>`
            }
            if(count == 3){
                var text = `<h5>ولي أمر الطالب: ${student.name}</h5>
            <p>
                نود احاطتكم بأن مجموع غياب ابنكم قد بلغ ${student.days.length} ايام
               بدون عذر تقبله المدرسة
            ٫
            لذا نرجو منك مراجعة المدرسة لاتخاذ الاجراء المناسب ومعرفة مسببات الغياب٫ حيث في حال تجاوز 15 يوم سيتم الرفع
            لادارة التعليم ونقل الطالب من المدرسة مع الحسم من درجات المواظبة٫
            وسيتم تطبيق نظام حماية الطفل.

            <br><br>
            اسم ولي الامر: ..............................................&nbsp;&nbsp;
                التاريخ: &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;/1446
            &nbsp;&nbsp;
            توقيع ولي الامر:......................
            <br><br>
            وكيل شؤون الطلاب:.................................................&nbsp;&nbsp;
            مدير المدرسة:..............................................
            </p>`
            }
            myWindow.document.write(`
            <html dir='rtl'>
                <head>
                    <link href='https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css' rel="stylesheet">
                    <link rel="preconnect" href="https://fonts.googleapis.com">
                    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                    <link href="https://fonts.googleapis.com/css2?family=Alexandria&display=swap" rel="stylesheet">
                    <style>
                        .g{
                            padding: 3px;
                        }
                        *{
                            font-family: 'Alexandria', sans-serif;
                        }
                        .c{
                            margin: 0px auto;
                        }
                    </style>
                </head>
                <body>
                    <div class='container-fluid'>
                        <div class='row'>
                            <div class='col-12 g'>
                                <div style='border: 2px solid #333; border-radius: 3px' class='g'>
                                    <div class='row'>
                                        <div class='col-4 text-center'>
                                            <img src='https://cdn-static.brmja.com/storage/scrapped/6319866ea8712498504411.jpg' style='width: 160px'>
                                            <br>
                                            وزارة التعليم
                                            <br>
                                            ${g.school_name}
                                        </div>
                                        <div class='col-4 text-center'>
                                            <img src='https://cdn-static.brmja.com/storage/scrapped/62793ceb03c19132886968.jpg' style='width: 160px'>
                                        </div>
                                        <div class='col-4'>
                                            <br>
                                            العام: 1446هـ
                                            <br>
                                            الفصل الدراسي: الثالث
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class='col-4 c g'>
                                <div class='text-center'>
                                    <h4 style='border: 1px dashed #999; border-radius: 3px' class='g'>
                                         إنذار غياب طالب
                                    </h4>
                                </div>
                            </div>
                            <div class='col-12'></div>
                            <div class='col-6 c g'>
                                <div class='text-center'>
                                    <span style='width: 100%' class='g'>
                                        اسم الطالب: ${student.name}
                                    </span>
                                </div>
                            </div>
                            <div class='col-6 c g'>
                                <div class='text-center'>
                                    <span style='width: 100%' class='g'>
                                        الفصل: ${student.classroom}
                                    </span>
                                </div>
                            </div>
                            <div class='col-12 g'></div>
                            <div class='col-12' style='border-top: dashed 2px #777'></div>
                            <div class='col-10 c'>
                                <br>
                                <div style=' padding: 10px; border-radius: 2px'>
                                    ايام الغياب
                                    <table class='table table-sm table-bordered table-striped'>
                                        <thead>
                                            <th>
                                                م
                                            </th>
                                            <th>
                                                التاريخ
                                            </th>
                                            <th>
                                                اليوم
                                            </th>
                                        </thead>
                                        <tbody>
                                            ${days}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class='col-12'></div>
                            <div class='col-4 c text-center'>
                                <h4 style='padding: 5px; border: solid 1px #333; border-radius: 10px 10px 0px 0px'>
                                    ${count == 1 ? 'الانذار الاول' : (
                                        count == 2 ? 'الانذار الثاني' : 'الانذار الثالث'
                                    )}
                                </h4>
                            </div>
                            <div class='col-12 g' style='position: relative; top: -12px'>
                                <div style='padding: 10px; border: 1px solid #333; border-radius: 3px;'>${text}</div>
                            </div>
                        </div>
                    </div>
                </body>
            </html>
            `);
            myWindow.document.close();
            myWindow.focus();
            setTimeout(function(){
                myWindow.print(); 
            }, 500)
        }
    }
}
</script>

<style>

</style>